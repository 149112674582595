import React from 'react';
import { observer} from 'mobx-react';
import { DataStore, DataStoreContext } from '../data/data-store'
import { UserOutlined,PlusCircleTwoTone,DeleteTwoTone,MailTwoTone,EditTwoTone,PlusSquareTwoTone } from '@ant-design/icons';
import { Space,Avatar, Button, Row, Col, Select, InputNumber } from 'antd'
import { AssignmentLine } from './assignment-line'

export const UserLine = observer((props:any) => {

  const data:DataStore = React.useContext(DataStoreContext)!
  const url='http://nope.nope'
  const name='jason henriksen'
  const email='jason.henriksen@gmail.com'

  const idx = props.idx

  console.log(data.userDataList[idx].assignments.length)
  const assignmentList   = data.userDataList[idx].assignments.map( (a,curIdx)=>{
    return (<AssignmentLine userIdx={idx} assignmentIdx={curIdx}/>)
  })

    return (
      <div onMouseEnter={()=>{data.mouseIdx=idx}} style={{display:'flex',alignItems:'center',alignContent:'center'}}>
        <div style={{maxWidth:80}}>
            <Button 
                icon={<EditTwoTone />}
                style={{
                    opacity:data.mouseIdx===idx?1:0,
                    transition: 'all 0.5s ease-out'
                  }          
                }
                onClick={()=>{
                  data.userEditIdx=idx
                }}
              >Edit</Button>
          </div>

          <div style={{maxWidth:40,padding:5}}>
            <Avatar icon={<UserOutlined />} />
          </div>
          <div style={{textAlign:'left',maxWidth:250,minWidth:250,}}>
            {data.userDataList[idx].name}<br/>
            {data.userDataList[idx].email}
          </div>
          {idx===data.userEditIdx &&
            <div>
              {assignmentList}
            </div>
          }
          <div style={{flexGrow:1}}>
            &nbsp;
          </div>


          
      </div>
    )


  
})