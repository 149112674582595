import { makeAutoObservable } from "mobx"

export enum TeamRoleEnum {
  Contributor = "Contributor",
  TeamMgr = "Manager",
  ProjectMgr = "Project Manager",
  ProductOwner = "Product Owner",
}


export class AssignmentData
{
  pct:number=100
  team:string='none'
  role:TeamRoleEnum=TeamRoleEnum.Contributor
}

export class UserData
{
  name:string=''
  email:string=''
  assignments:AssignmentData[]=[]
  lastRead:string|null=null
  lastWrite:string|null=null

  constructor(name:string,email:string){
    this.name = name
    this.email = email
    this.assignments = [new AssignmentData()]
    makeAutoObservable(this)
  }
}
