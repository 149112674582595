import React, {useEffect,useState} from 'react';
import { observer} from 'mobx-react';
import { DataStore, DataStoreContext } from '../data/data-store'
import { GoogleLogin, useGoogleLogin } from '@react-oauth/google';
import { AssignmentLine } from './assignment-line'
import { Avatar, Button } from 'antd'
import { getEnvironmentData } from 'worker_threads'

export const LoginState = observer((props:any) => {

  const data:DataStore = React.useContext(DataStoreContext)!


    return (
      <div style={{float:'right'}}>
        {data.gData && data.gData.picture &&
        <div>
          
          {/*THIS IS NEEDED!  You can't put referrerPolicy on avatary, but including this sorts out the google issue 
          <Avatar style={{border:'1px solid grey'}} src={data.gData?.picture} alt='UserProfileIcon' size={45}/>
          */}
          <img style={{width:'50px',height: '50px'}}  src={data.gData?.picture} referrerPolicy="no-referrer"/>
          
        </div>

        }

        {!data.gData && 
              <GoogleLogin 
                onSuccess={(e)=>{
                  console.log(JSON.stringify(e,null,2))
                  data.gLogin = e
                  data.getUserData()
                }} 
                onError={()=>{console.log('login failure')}}
                auto_select
                useOneTap
              />
        }
      </div>
    )


  
})