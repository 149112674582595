import React from 'react';
import {DataStoreContext,DataStoreImpl} from './data/data-store';
import { UserList } from './ui/user-list'
import Layout, { Content, Footer, Header } from 'antd/es/layout/layout'
import Sider from 'antd/es/layout/Sider'
import { bgc, contentStyle, footerStyle, headerStyle, siderStyle } from './style/style-blocks'
import { PlaceHolder } from './ui/place-holder'
import { LoginState } from './ui/login-state'

function App() {
  return (
    <div className="App" style={{backgroundColor:'#ebebe0'}}>
      <DataStoreContext.Provider value={DataStoreImpl}>
        asdf
        asfse

      <Layout style={{position:'absolute',top:0,bottom:0,left:0, right:0, backgroundColor:'#ebebe0'}}>
        <Sider style={siderStyle}>
          
        </Sider>
        <Layout style={{backgroundColor:bgc}}>
          <Header style={headerStyle}>
              <LoginState/>
          </Header>
          <Content style={{overflow:'auto', ...contentStyle}}>
            <PlaceHolder/>
          </Content>
          <Footer style={footerStyle}>
            &nbsp;
          </Footer>
        </Layout>
      </Layout>

      </DataStoreContext.Provider>
    </div>
  );
}

export default App;
