import React from 'react';
import { observer} from 'mobx-react';
import { DataStore, DataStoreContext } from '../data/data-store'
import { UserOutlined,CloseSquareTwoTone,DeleteTwoTone,MailTwoTone,EditTwoTone,PlusSquareTwoTone } from '@ant-design/icons';
import { Space,Avatar, Button, Row, Col, Select, InputNumber, Input } from 'antd'
import { UserData } from '../model/user-data'
import { UserLine } from './user-line'
import TweenOne from 'rc-tween-one';

export const PlaceHolder = observer((props:any) => {

  const [paused, setPaused] = React.useState(true);
  const [moment, setMoment] = React.useState<number|undefined>(undefined);
  const [reverse, setReverse] = React.useState(false);

  const data:DataStore = React.useContext(DataStoreContext)!
  const url='http://nope.nope'
  const name='jason henriksen'
  const email='jason.henriksen@gmail.com'

  const list = data.userDataList.map((ud:UserData, idx:number)=>{
    return <UserLine idx={idx}/>
  })


  return (

    <div style={{height:'100%',display: 'flex',justifyContent:'center',alignItems: 'center'}}>
    <div style={{margin:'auto',textAlign:'center',fontSize:72,fontFamily: 'Libre Baskerville'}}>      
        OKR<br/>
        Systems
    </div>
    </div>
)

})