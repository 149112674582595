import React from 'react';
import { observer} from 'mobx-react';
import { DataStore, DataStoreContext } from '../data/data-store'
import { PlusCircleTwoTone,CloseCircleTwoTone,DeleteTwoTone,MailTwoTone,EditTwoTone,PlusSquareTwoTone } from '@ant-design/icons';
import { Space,Avatar, Button, Row, Col, Select, InputNumber } from 'antd'
import { AssignmentData } from '../model/user-data'

export const AssignmentLine = observer((props:any) => {

  const data:DataStore = React.useContext(DataStoreContext)!
  const url='http://nope.nope'
  const name='jason henriksen'
  const email='jason.henriksen@gmail.com'

  const uidx:number = props.userIdx
  const aidx:number = props.assignmentIdx

  return (
          <div>
            <Space.Compact>
              <InputNumber
                defaultValue={data.userDataList[uidx].assignments[aidx].pct}
                min={0}
                max={100}
                formatter={(value) => `${value}%`}
                onChange={(e)=>{ data.userDataList[uidx].assignments[aidx].pct=e||100 }}
              />            
              <Select
                defaultValue={data.userDataList[uidx].assignments[aidx].team}
                style={{
                  width: 200,
                }}
                options= {[
                  {label: '>> Add New Team <<',value: 'addteam',},
                  {label: 'none',value: 'unassigned',},
                  {label: 'pmo',value: 'pmo',},
                  {label: 'api',value: 'api',},
                  {label: 'ui',value: 'ui',},
                  {label: 'bp',value: 'bp',},
                  {label: 'sqa',value: 'sqa',},
                ]}
                onChange={(e)=>{ data.userDataList[uidx].assignments[aidx].team=e }}

              />
            <Select
              defaultValue={data.userDataList[uidx].assignments[aidx].role}
              style={{width: 200,}}
              onChange={(e)=>{ data.userDataList[uidx].assignments[aidx].role=e }}
              options={[
                {
                  label: 'Grants read/write OKRs on the assigned team',
                  options: [
                    {
                      label: 'Contributor',
                      value: 'Contributor',
                    },
                  ],
                },
                {
                  label: 'Grants read/write on the assigned team and any sub-teams',
                  options: [
                    {
                      label: 'Team Lead',
                      value: 'Team Lead',
                    },
                    {
                      label: 'Product Owner',
                      value: 'Product Owner',
                    },
                    {
                      label: 'Project Manager',
                      value: 'Project Manager',
                    },
                  ],
                },
                {
                  label: 'Grants OKRs plus assigns people to the team',
                  options: [
                    {
                      label: 'Manager',
                      value: 'Manager',
                    },
                  ],
                },
              ]}
            />


            </Space.Compact>
            {aidx!==0 && <span  >
                <CloseCircleTwoTone 
                  onClick={(e)=>{data.userDataList[uidx].assignments.splice(aidx,1)}}
                  style={{padding:5,transform:'translateY(5px)'}} />
              </span> 
            }
            {aidx===0 && <span onClick={(e)=>{data.userDataList[uidx].assignments.push(new AssignmentData())}}>
                  <PlusCircleTwoTone style={{padding:5,transform:'translateY(5px)'}} />
              </span> 
            }
          </div>
  )
})

  
