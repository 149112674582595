import React from 'react'
import {observable, action, computed, makeAutoObservable } from 'mobx'
import { UserData } from '../model/user-data'
import { configure } from "mobx"
import { useGoogleLogin } from '@react-oauth/google'
import jwtDecode from 'jwt-decode'

configure({
    enforceActions: "never",
})


export class DataStore {

  constructor(){
    makeAutoObservable(this)
  }

  counter:number = 0
  color:string='#fff'

  inputName:string = ''
  inputEmail:string = ''

  userEditIdx=-1
  mouseIdx=-1

  gLogin:any=null
  gData:any=null

  invite={
    moment:0,
    paused:false,
    reverse:false,    
  }


  userDataList:UserData[] = []
  addUser(){
    this.userDataList.push(observable(new UserData(this.inputName,this.inputEmail)))  
    this.inputName=''
    this.inputEmail=''
  }

  getUserData(){

    console.log()

    this.gData = jwtDecode(this.gLogin.credential)    
    console.log(JSON.stringify(this.gData,null,2))

    // fetch(`https://www.googleapis.com/oauth2/v1/userinfo`, {
    //   mode:'no-cors',
    //   credentials: 'include',
    //   cache:'no-cache',
    //   headers: {
    //       Accept: 'application/json',
    //   }}
    // )
    // .then((res) => {
    //   console.log(JSON.stringify(res,null,2))
    // })
    // .catch((err) => console.log(err));

  }  

}

export const DataStoreImpl = new DataStore()

export const DataStoreContext = React.createContext<DataStore|null>(null)

export default DataStore

function jwt_decode(credential: any) {
  throw new Error('Function not implemented.')
}
